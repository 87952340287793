import { openDialog } from '@mfl/common-components';
import { Link } from '@wisestamp/links-gateway-sdk';

import AddEditLinkDialog from './add-edit-link-dialog.vue';
import strings from './links.strings';

export async function addEditLink(link?: Link): Promise<Link | undefined> {
  const headerText = link ? strings.dialogTitleEdit : strings.dialogTitleAdd;

  return await openDialog<Link | undefined, Link>({
    aid: (link ? 'EDIT' : 'ADD') + '_LINK_DIALOG',
    headerText,
    component: AddEditLinkDialog,
    showTopSeparator: true,
    input: clone(link), // guard the original by cloning
  });
}

function clone(link?: Link): Link | undefined {
  if (!link) return undefined;

  return JSON.parse(JSON.stringify(link));
}
