<script setup lang="ts">
import { ref } from 'vue';
import {
  WsButton,
  IDialog,
  WsDialogActions,
  WsDialogForm,
  WsDialogSection,
  WsAlert,
} from '@mfl/common-components';
import {
  linksGateway,
  DeleteResponse,
  Link,
} from '@wisestamp/links-gateway-sdk';
import strings from './links.strings';

const props = defineProps<{
  dialog: IDialog<Link, DeleteResponse>;
}>();

const busy = ref<boolean>(false);
const link = ref<Link>({});

if (props.dialog.input) {
  link.value = props.dialog.input;
}

async function deleteLink() {
  busy.value = true;

  let surveyId = '';
  if (link.value.type === 'survey' && link.value.redirectUrl) {
    surveyId = link.value?.redirectUrl?.split('/').pop() || '';
  }

  const res = await linksGateway.delete({
    ids: [link.value.id || ''],
    linkType: link.value.type,
    surveyId,
  });

  if (res.success) {
    props.dialog.close(res);
  } else {
    console.error('Error deleting link. Please try again later.', res.error);
  }
  busy.value = false;
}
</script>

<template>
  <WsDialogForm @submit="deleteLink">
    <WsDialogSection class="delete-confirmation-section">
      <b>{{ strings.dialogTextDelete }}</b>
      <WsAlert
        v-if="link.type === 'survey'"
        aid="DELETE_LINK_DIALOG_ALERT"
        :open="true"
        type="warning"
      >
        {{ strings.customLinkWarning }}
      </WsAlert>
      {{ strings.dialogQuestionDelete }}
    </WsDialogSection>
    <WsDialogActions>
      <WsButton
        aid="LINK_DIALOG_CANCEL_BUTTON"
        label="Cancel"
        color="gray-500"
        variant="outlined"
        @click="props.dialog.close()"
      />
      <WsButton
        type="submit"
        color="danger"
        aid="LINK_DIALOG_DELETE_BUTTON"
        :label="strings.dialogDeleteButton"
        :loading="busy"
      />
    </WsDialogActions>
  </WsDialogForm>
</template>

<style scoped>
.delete-confirmation-section {
  max-width: 400px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  gap: 17px;
}
</style>
