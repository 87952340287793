<script setup lang="ts">
import {
  WsDialogSection,
  IDialog,
  WsButton,
  WsDialogActions,
  WsDialogForm,
} from '@mfl/common-components';
import { onMounted, ref } from 'vue';
import { Link } from '@wisestamp/links-gateway-sdk';
import QRCode from 'qrcode';
import strings from './links.strings';

const props = defineProps<{
  dialog: IDialog<Link | undefined, Link>;
}>();
const canvas = ref<HTMLCanvasElement | null>(null);
const link = ref<string>('');
const qrOptions: object = {
  errorCorrectionLevel: 'H',
  type: 'image/png',
  scale: 7,
  margin: 5,
};

if (props.dialog.input) {
  link.value = props.dialog.input.redirectUrl as string;
}

const downloadQRCode = () => {
  if (canvas.value !== null && !!props.dialog.input) {
    const linkElement = document.createElement('a');
    linkElement.setAttribute('download', `${props.dialog.input.title}.png`);
    linkElement.setAttribute(
      'href',
      canvas.value
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
    );
    linkElement.click();
  }
};

onMounted(() => {
  QRCode.toCanvas(canvas?.value, link.value, qrOptions, function (error) {
    if (error) console.error(error);
  });
});
</script>

<template>
  <WsDialogForm>
    <WsDialogSection class="link-generate-qr-section">
      <canvas ref="canvas" class="canvas"></canvas>
    </WsDialogSection>
  </WsDialogForm>
  <WsDialogActions>
    <WsButton
      label="Cancel"
      color="gray-500"
      variant="outlined"
      aid="GENERATE_QR_CODE_DIALOG_CLOSE_BUTTON"
      @click="props.dialog.close()"
    />
    <WsButton
      aid="GENERATE_QR_CODE_DIALOG_DOWNLOAD_BUTTON"
      @click="downloadQRCode"
    >
      {{ strings.download }}
    </WsButton>
  </WsDialogActions>
</template>
